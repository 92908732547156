import { classNames } from "~/lib/util";

type Props = {
	input: number,
	output: number
}

export function PriceImpact({ input, output }: Props) {
	if (!input || !output) {
		return '';
	}

	let impact = Math.round(((output - input) / input) * 10000) / 100;
	let impactString = impact.toLocaleString();

	if (impact > 0) {
		impactString = '+' + impactString;
	}

	return (
      <span className={classNames(impact < -5 ? 'text-red-500' : 'text-black/50')}>{impactString + '%'}</span>
      // input + ' '+ output
   );

}